import endpoints from './endpoints'
import getApiData from './getApiData'

export default async function getMetricEvents({ farm, namespace, year, paddockIds, token }) {
  const events = await getApiData(
    endpoints.metricEvents,
    {
      farmId: farm.id,
      namespace,
      year,
      paddockIds,
    },
    token,
  )
  return events
}
