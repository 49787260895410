import { useAuth0 } from '@auth0/auth0-react'
import { useEffect, useState } from 'react'
import { useCustomSnackbarError } from '../../utils/Snackbar/useCustomSnackbarError'
import { ruutsApi } from '../../services/ruutsApi'

export const useFarm = ({ id }) => {
  const [farm, setFarm] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const { user, getAccessTokenSilently } = useAuth0()
  const { handleError } = useCustomSnackbarError()

  useEffect(() => {
    async function loadFarm() {
      try {
        setIsLoading(true)
        const token = await getAccessTokenSilently()
        const result = await ruutsApi.getFarm({ id, user, token })

        if (result) {
          setFarm(result)
        }
      } catch (error) {
        handleError(error)
      } finally {
        setIsLoading(false)
      }
    }

    if (id) {
      loadFarm()
    }
  }, [getAccessTokenSilently, handleError, id, user])

  return { farm, isLoading }
}
