import { Box, List, ListItem, ListItemText, Skeleton, styled, Typography } from '@mui/material'
import { Fragment } from 'react'
import { AttestationReportDetail } from './AttestationReportDetail'
import { useFarm } from '../../../hooks/data/useFarm'
import { useSamplingAreas } from '../../../hooks/data/useSamplingAreas'
import { Map } from '../Map/Map'

const NoBulletList = styled(List)({
  listStyleType: 'none',
  padding: 0,
})

const TextWithLineBreaks = ({ text, textAlign = 'justify' }) => {
  return (
    <Typography sx={{ textAlign }} variant="body2">
      {text.split('\n').map((line, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <Fragment key={index}>
          {line}
          <br />
        </Fragment>
      ))}
    </Typography>
  )
}

const formatDate = date => {
  return new Date(date).getFullYear()
}

const getYearsBetweenDates = (startDate, endDate) => {
  const startYear = new Date(startDate).getFullYear()
  const endYear = new Date(endDate).getFullYear()
  const years = []

  for (let year = startYear; year <= endYear; year++) {
    years.push(year)
  }

  return years
}

export const AttestationReport = ({ report }) => {
  const {
    date,
    attestationContent,
    city,
    province,
    country,
    responsible,
    startYear,
    periodStart,
    periodEnd,
    includedMetrics,
    farmId,
  } = report

  const years = getYearsBetweenDates(periodStart, periodEnd)

  const { farm, isLoading } = useFarm({ id: farmId })
  const { samplingAreas, isLoading: isSamplingAreasLoading } = useSamplingAreas({ farmId })

  if (isLoading || isSamplingAreasLoading) return <Skeleton />

  const perimeter = farm?.toGeoJSON

  return (
    <Box sx={{ background: '#efefef' }}>
      <img alt="attestation-Header" height="200px" src="/reports/attestation.png" width="100%" />
      <Box sx={{ display: 'flex', gap: '16px', flexDirection: 'column', p: '20px' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
          <Typography typography="body2">Fecha: {date.format('DD/MM/YYYY')}</Typography>
          <Typography sx={{ fontWeight: 'bold' }} typography="h6">
            INFORME DE CONSENTIMIENTO - ESTABLECIMIENTO {farm?.name}
          </Typography>
          <TextWithLineBreaks text={attestationContent} textAlign="justify" />
          <Typography sx={{ fontWeight: 'bold' }} typography="h6">
            ESTABLECIMIENTO {farm?.name} - {city} - {province} - {country}
          </Typography>
          <NoBulletList>
            <ListItem>
              <ListItemText>
                <Typography typography="body2">Inscripción: {formatDate(startYear)}</Typography>
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText>
                <Typography typography="body2">
                  Periodo: {formatDate(periodStart)} - {formatDate(periodEnd)}
                </Typography>
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText>
                <Typography typography="body2">Responsable del proyecto: {responsible}</Typography>
              </ListItemText>
            </ListItem>
          </NoBulletList>
        </Box>

        <div className="html2pdf__page-break" />

        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
          <Typography sx={{ fontWeight: 'bold' }} typography="h5">
            Perímetro del establecimiento y estratificación
          </Typography>
          <Map isImage flexDirection="column" mapHeight={600} mapWidth={600} perimeter={perimeter}>
            <Map.Layer layer={samplingAreas} />
            <Map.Legend layers={[perimeter, samplingAreas]} />
          </Map>
        </Box>
        {years.map(year => (
          <Fragment key={year}>
            <AttestationReportDetail farm={farm} includedMetrics={includedMetrics} year={year} />
          </Fragment>
        ))}
      </Box>
    </Box>
  )
}
