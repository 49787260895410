import { useAuth0 } from '@auth0/auth0-react'
import { useContext, useEffect, useState } from 'react'
import AppContext from '../../context/appContext'
import { useCustomSnackbarError } from '../../utils/Snackbar/useCustomSnackbarError'
import { ruutsApi } from '../../services/ruutsApi'

export const useDataCollectionStatement = year => {
  const [dataCollectionStatement, setDataCollectionStatement] = useState(null)
  const { handleError } = useCustomSnackbarError()
  const [isLoading, setIsLoading] = useState(true)
  const { currentFarm, userRoleSelected } = useContext(AppContext)
  const { getAccessTokenSilently } = useAuth0()

  useEffect(() => {
    if (!year || !currentFarm || !userRoleSelected) return

    async function loadDataCollectionStatement() {
      try {
        setIsLoading(true)
        const token = await getAccessTokenSilently()
        const result = await ruutsApi.dataCollectionStatementService.getByFarmYear(
          currentFarm.id,
          year,
          userRoleSelected,
          token,
        )
        if (result) {
          setDataCollectionStatement(result)
        }
      } catch (error) {
        handleError(error)
      } finally {
        setIsLoading(false)
      }
    }

    loadDataCollectionStatement()
  }, [year, currentFarm, userRoleSelected, getAccessTokenSilently, handleError])

  return { dataCollectionStatement, isLoading }
}
