import { useAuth0 } from '@auth0/auth0-react'
import { useContext, useEffect, useState } from 'react'
import AppContext from '../../context/appContext'
import { ruutsApi } from '../../services/ruutsApi'
import { useCustomSnackbarError } from '../../utils/Snackbar/useCustomSnackbarError'

export const useFormDefinitions = () => {
  const [formDefinitions, setFormDefinitions] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const { programConfig } = useContext(AppContext)
  const { getAccessTokenSilently } = useAuth0()
  const { handleError } = useCustomSnackbarError()

  useEffect(() => {
    async function loadFormDefinitions() {
      try {
        setIsLoading(true)
        const token = await getAccessTokenSilently()
        const result = await ruutsApi.getDataCollectionFormDefinitions(programConfig, token)

        if (result) {
          setFormDefinitions(result)
        }
      } catch (error) {
        handleError(error)
      } finally {
        setIsLoading(false)
      }
    }

    loadFormDefinitions()
  }, [programConfig, getAccessTokenSilently, handleError])

  return { formDefinitions, isLoading }
}
