let tempReport

// TODO: Implement POST /reports
export const generateReport = async ({ reportData, token }) => {
  tempReport = reportData
  tempReport.id = 1
  const promise = new Promise(resolve => {
    setTimeout(() => {
      resolve(tempReport)
    }, 500)
  })
  return promise
}

// TODO: Implement GET /reports/:id
export const getReport = async ({ reportId, token }) => {
  const promise = new Promise(resolve => {
    setTimeout(() => {
      resolve(tempReport)
    }, 500)
  })
  return promise
}
