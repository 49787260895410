import * as turf from '@turf/turf'
import tokml from 'geojson-to-kml'
import { Parser as CSVParser } from '@json2csv/plainjs'
import { ruutsApi } from '.'
import { GeoJSON } from '../../utils/GeoJSON/index'
import getExclusionAreas from '../farmMapping/getExclusionAreas'
import getFarmSubdivisionById from '../farmMapping/getFarmSubdivisionById'
import getMonitoringSites from '../farmMapping/getMonitoringSites'
import getMonitoringSoc from '../farmMapping/getMonitoringSoc'
import { localRefData } from '../localRefData/index'
import { errors } from './errors'

const convertToCSV = async arrayObjects => {
  const parser = new CSVParser()
  return parser.parse(arrayObjects)
}

export const getMapaToDownload = async (
  currentFarm,
  token,
  subdivisionId,
  includeSocInformation,
) => {
  const perimeter = currentFarm.toGeoJSON
  let paddocks = []
  if (subdivisionId !== '') {
    const subdivision = await getFarmSubdivisionById(currentFarm, token, subdivisionId)
    paddocks = await Promise.all(
      subdivision.paddockIds.map(async paddockId => {
        const result = await ruutsApi.paddocks.getPaddockById({ id: paddockId, token })
        if (result.length !== 1)
          throw new errors.BusinessEntityError({
            message: `Error de inconsistencia al descargar el paddock id ${paddockId}`,
          })
        return result[0].toGeoJSON
      }),
    )
  }

  // Sampling areas
  const rawSamplingAreas = await ruutsApi.samplingAreas.getUncroppedByFarm({
    farmId: currentFarm.id,
    token,
  })
  const samplingAreas = rawSamplingAreas?.map(samplingArea => samplingArea?.toGeoJSON)

  // Exclusion areas
  const exclusionTypes = await localRefData.getDataByKey(localRefData.keys.exclusionAreaTypes)
  const rawExclusionAreas = await getExclusionAreas(currentFarm, token)
  const exclusionAreas = rawExclusionAreas?.map(exclusionArea => {
    const feature = exclusionArea.toGeoJSON
    const exclusionAreaTypeDescription = exclusionTypes.find(
      exclusionType => exclusionType.id === feature.properties.exclusionAreaTypeId,
    )
    if (exclusionAreaTypeDescription) {
      feature.properties.exclusionAreaTypeDescription = exclusionAreaTypeDescription.en_US
    }
    if (feature?.properties?.name) delete feature.properties.name
    return feature
  })

  let monitoringSites = []
  let extraInformation = null
  if (includeSocInformation) {
    // Monitoring sites and SOC information
    const { monitoringSocSites, monitoringSites: allMonitoringSites } = await getMonitoringSoc(
      currentFarm,
      token,
    )
    monitoringSites = monitoringSocSites
    extraInformation = allMonitoringSites
  } else {
    // Monitoring sites
    const rawMonitoringSites = await getMonitoringSites(currentFarm, token)
    monitoringSites = rawMonitoringSites?.map(monitoringSite => monitoringSite?.toGeoJSON)
  }

  function filterPrivateInformation(feature) {
    const { properties } = feature
    const {
      name,
      color,
      area,
      exclusionAreaTypeId,
      exclusionAreaTypeDescription,
      type,
      lat,
      lon,
      hasGrazingManagement,
      is_random_site,
      monitoring_site_id,
      monitoring_site_name,
      monitoring_task_id,
      monitoring_task_key,
      sampling_area_id,
      farm_id,
      station_id,
      stratum_id,
      farm_name,
      farm_code,
    } = properties

    const propertiesToKeep = {
      type,
      name,
      area,
      color,
      exclusionAreaTypeId,
      exclusionAreaTypeDescription,
      hasGrazingManagement,
      lat,
      lon,
      is_random_site,
      monitoring_site_id,
      monitoring_site_name,
      monitoring_task_id,
      monitoring_task_key,
      sampling_area_id,
      farm_id,
      station_id,
      stratum_id,
      farm_name,
      farm_code,
    }

    // Filter out attributes with undefined values
    const propertiesWithValue = Object.fromEntries(
      Object.entries(propertiesToKeep).filter(
        ([_key, value]) => ![undefined, null].includes(value),
      ),
    )

    // TODO: Some total hectares are not being calculated correctly
    // Recalculate area if it's present because it might be incorrect
    // This is a temporary fix until we can calculate the area correctly on the backend
    if (propertiesWithValue.area) {
      const featureArea = GeoJSON.hectareArea(feature)
      propertiesWithValue.area = featureArea
    }

    return { ...feature, properties: propertiesWithValue }
  }

  const geojson = turf.featureCollection(
    [perimeter, ...paddocks, ...samplingAreas, ...exclusionAreas, ...monitoringSites].map(feature =>
      filterPrivateInformation(feature),
    ),
  )

  const kml = tokml(geojson)

  const map = {
    geojson: JSON.stringify(geojson),
    kml,
    csv: extraInformation && convertToCSV(extraInformation),
  }

  return map
}
