import { useState, useEffect } from 'react'
import * as turf from '@turf/turf'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import AddIcon from '@mui/icons-material/Add'
import { useAuth0 } from '@auth0/auth0-react'
import lfStore from '../../lfstore/lfStore'
import EventPanel from './EventPanel'
import EventMap from './EventMap'
import EventsList from './EventsList'
import { GeoJSON } from '../../utils/GeoJSON/index'
import { ruutsApi } from '../../services/ruutsApi/index'
import endpoints from '../../services/ruutsApi/endpoints'
import { useViewOnly } from '../../hooks/useViewOnly'

const EventPlanning = () => {
  const { getAccessTokenSilently } = useAuth0()
  const [samplingAreas, setSamplingAreas] = useState()
  const [farm, setFarm] = useState()
  const [perimeter, setPerimeter] = useState()
  const [monitoringWorkflows, setMonitoringWorkflows] = useState()
  const [selectedSamplingAreas, setSelectedSamplingAreas] = useState([])
  const [monitoringSites, setMonitoringSites] = useState({})
  const [selectedMonitoringSites, setSelectedMonitoringSites] = useState([])
  const [mapWidth] = useState(window.innerWidth / 2)
  const [mapHeight] = useState(window.innerHeight)
  const [createEventView, setCreateEventView] = useState(false)
  const { isViewOnly } = useViewOnly()

  useEffect(() => {
    async function getFarm() {
      const token = await getAccessTokenSilently()
      const f = await lfStore.getItem('currentFarm')
      const sA = await ruutsApi.samplingAreas.getCroppedByFarm({ farmId: f.id, token })
      const sites = await ruutsApi.getApiData(endpoints.sites, { farmId: f.id }, token)
      const mW = await ruutsApi.getApiData(endpoints.workflows, null, token)

      const fC = turf.featureCollection([])

      sA.forEach(sArea => {
        const feature = sArea.toGeoJSON
        feature.properties.area = GeoJSON.hectareArea(feature).toFixed(2)
        fC.features.push(feature)
      })

      setMonitoringWorkflows(mW)
      setFarm(f)
      setPerimeter(f.toGeoJSON)
      setSamplingAreas(fC)

      if (sites) {
        const sitesObj = {}
        sA.forEach(area => {
          sitesObj[area.name] = sites.map(site => {
            if (site.samplingAreaId === area.id) {
              const feature = turf.point([site.location[1], site.location[0]])
              feature.properties.name = site.name
              feature.properties.id = site.id
              feature.properties.strata = area.name
              return feature
            }
            return undefined
          })

          sitesObj[area.name] = sitesObj[area.name].filter(site => {
            return site !== undefined
          })
        })

        setMonitoringSites(sitesObj)
      }
    }
    getFarm()
  }, [getAccessTokenSilently])

  return (
    <Box>
      {!createEventView && (
        <Box sx={{ padding: '10px', textAlign: 'right' }}>
          <Button
            disabled={isViewOnly}
            startIcon={<AddIcon />}
            variant="outlined"
            onClick={() => setCreateEventView(true)}
          >
            Crear Evento
          </Button>
        </Box>
      )}
      {!createEventView && <EventsList farm={farm} />}
      {createEventView && (
        <Grid container>
          <Grid item xs={6}>
            <EventPanel
              farm={farm}
              monitoringSites={monitoringSites}
              monitoringWorkflows={monitoringWorkflows}
              samplingAreas={samplingAreas}
              selectedMonitoringSites={selectedMonitoringSites}
              selectedSamplingAreas={selectedSamplingAreas}
              setCreateEventView={setCreateEventView}
              setSelectedMonitoringSites={setSelectedMonitoringSites}
              setSelectedSamplingAreas={setSelectedSamplingAreas}
            />
          </Grid>
          <Grid item xs={6}>
            <EventMap
              mapHeight={mapHeight}
              mapWidth={mapWidth}
              monitoringSites={monitoringSites}
              perimeter={perimeter}
              samplingAreas={samplingAreas}
              selectedMonitoringSites={selectedMonitoringSites}
              selectedSamplingAreas={selectedSamplingAreas}
            />
          </Grid>
        </Grid>
      )}
    </Box>
  )
}

export default EventPlanning
