import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import { Box, Button, CircularProgress, Paper, Typography } from '@mui/material'
import html2pdf from 'html2pdf.js'
import { useContext, useEffect, useRef } from 'react'
import { useNavigate, useParams } from 'react-router'
import AppContext from '../../context/appContext'
import { useReport } from '../../hooks/data/useReport'
import { NavHeader } from '../NavHeader/NavHeader'
import { AttestationReport } from './Attestation/AttestationReport'

export const Report = () => {
  const { setHideNavbar } = useContext(AppContext)
  const { reportType, reportId } = useParams()
  const navigate = useNavigate()
  const { report, isLoading } = useReport({ reportId })
  const contentRef = useRef(null)

  const renderReport = {
    Attestation: <AttestationReport report={report} />,
  }

  useEffect(() => {
    if (!isLoading && !report) {
      navigate('/reports')
    }
  }, [report, isLoading, navigate])

  useEffect(() => {
    setHideNavbar(true)
    return () => {
      setHideNavbar(false)
    }
  }, [setHideNavbar])

  const handleNavBack = () => {
    navigate('/reports')
  }

  const generatePdf = () => {
    const element = contentRef.current
    const options = {
      filename: `${reportType}-${reportId}.pdf`,
      image: { type: 'jpeg', quality: 1 },
      jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
    }

    const style = document.createElement('style')
    document.head.appendChild(style)
    style.sheet?.insertRule('body > div:last-child img { display: inline-block; }')

    html2pdf()
      .set(options)
      .from(element)
      .save()
      .then(() => style.remove())
  }

  return (
    <>
      <NavHeader>
        <NavHeader.BackButton handleNavBack={handleNavBack}>
          <ArrowBackIosIcon />
        </NavHeader.BackButton>
        <NavHeader.Title>
          <Typography variant="bodyBold">{reportType}</Typography>
        </NavHeader.Title>
        <NavHeader.FarmDetails />
      </NavHeader>
      {isLoading ? (
        <Box
          sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Box sx={{ display: 'flex', width: '210mm', margin: '24px auto', p: '16px ' }}>
            <Button onClick={generatePdf}>Generar PDF</Button>
          </Box>
          <Paper
            sx={{
              width: '210mm',
              margin: '24px auto',
              backgroundColor: 'white',
              boxShadow: '0 0 0.5cm rgba(0,0,0,0.5)',
            }}
          >
            <div ref={contentRef}>{renderReport[reportType]}</div>
          </Paper>
        </>
      )}
    </>
  )
}
