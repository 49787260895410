import { CircularProgress, Typography } from '@mui/material'
import { useEffect, useMemo, useState } from 'react'
import { useFormDefinitions } from '../../hooks/data/useFormDefinitions'
import { useMetricEvents } from '../../hooks/data/useMetricEvents'
import lfStore from '../../lfstore/lfStore'
import { useCustomSnackbarError } from '../../utils/Snackbar/useCustomSnackbarError'
import { ExpandableDataTable } from '../ExpandableDataTable/ExpandableDataTable'
import { createColumns, processRecordData } from '../ExpandableDataTable/dataTableHelpers'

const ReportMetricsTable = ({ farmId, namespace, year, farmSubdivision }) => {
  const [rowData, setRowData] = useState()
  const [columns, setColumns] = useState()
  const { metricEvents, isLoading } = useMetricEvents({ farmId, namespace, year })

  const { formDefinitions, isLoading: isFormDefinitionsLoading } = useFormDefinitions()
  const { handleError } = useCustomSnackbarError()

  const formDefinition = useMemo(
    () => formDefinitions?.find(fd => fd.namespace === namespace),
    [formDefinitions, namespace],
  )

  useEffect(() => {
    async function setUpData() {
      if (!metricEvents?.length || !formDefinition || !farmSubdivision) return

      try {
        const refDataKeys = await lfStore.keys()
        const newColumns = await createColumns(formDefinition, [
          'metricYear',
          'paddocks',
          'notApplicable',
        ])
        setColumns(newColumns)
        const rows = await Promise.all(
          metricEvents.map(async record =>
            processRecordData({
              record,
              columnNames: newColumns.map(col => col.name),
              formDefinition,
              refDataKeys,
              farmSubdivision,
            }),
          ),
        )
        setRowData(rows)
      } catch (error) {
        handleError(error.message, error)
      }
    }
    setUpData()
  }, [formDefinition, metricEvents, handleError, farmSubdivision])

  if (isLoading || isFormDefinitionsLoading) return <CircularProgress />

  if (!metricEvents?.length)
    return <Typography typography="h6">No hay métricas para este año</Typography>

  return (
    columns &&
    rowData && (
      <ExpandableDataTable
        isAllExpanded
        columns={columns}
        customOptions={{
          expandableRowsOnClick: true,
          expandableRowsHeader: false,
          responsive: 'standard',
          elevation: 0,
        }}
        data={rowData}
        excludedDataKeys={['findings', 'isVerified', 'metricStatusId']}
        formDefinition={formDefinition}
      />
    )
  )
}

export default ReportMetricsTable
