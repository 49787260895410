import { grey } from '@mui/material/colors'

export const themeOptions = {
  components: {
    MUIDataTableBodyCell: {
      styleOverrides: {
        root: {
          textAlign: 'center',
          backgroundColor: 'white',
        },
      },
    },
    MUIDataTableHeadCell: {
      styleOverrides: {
        fixedHeader: {
          textAlign: 'center',
          backgroundColor: '#E3E9EF',
        },
        data: {
          color: grey[800],
          fontWeight: 'bold',
        },
        contentWrapper: {
          justifyContent: 'center',
        },
      },
    },
    MUIDataTableSelectCell: {
      styleOverrides: {
        headerCell: {
          textAlign: 'center',
          backgroundColor: '#E3E9EF',
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          fontFamily: 'Roboto',
          margin: 0,
          padding: 0,
          color: grey[800],
          fontSize: '0.8rem',
        },
        fieldset: {
          color: grey[600],
          border: '1px solid',
          borderColor: grey[400],
          borderRadius: '4px 4px 4px 4px',
          padding: '15px',
        },
        label: {
          fontSize: '0.8rem',
        },
        tab: {
          textTransform: 'none',
        },
      },
    },
  },
  typography: {
    fontFamily: 'Roboto',
    bodyBold: {
      fontFamily: 'Roboto',
      fontWeight: 600,
    },
    captionLight: {
      fontSize: '10px',
      fontFamily: 'Roboto',
      color: grey[600],
    },
    h1: {
      fontFamily: 'Nunito Sans',
    },
    h2: {
      fontFamily: 'Nunito Sans',
    },
    h3: {
      fontFamily: 'Nunito Sans',
    },
    h4: {
      fontFamily: 'Nunito Sans',
    },
    h5: {
      fontFamily: 'Nunito Sans',
    },
    h6: {
      fontFamily: 'Nunito Sans',
      fontWeight: 400,
    },
    label: {
      fontSize: '0.8rem',
      fontWeight: 600,
    },
    body1: {
      fontSize: '0.8rem',
    },
  },
}
