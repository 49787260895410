export const roles = {
  Auditor: {
    es_AR: 'Auditor',
    es_UY: 'Auditor',
    en_US: 'Auditor',
  },
  'Field Technician': {
    es_AR: 'Técnico',
    es_UY: 'Técnico',
    en_US: 'Field technician',
  },
  'Data Scientist': {
    es_AR: 'Científico de datos',
    es_UY: 'Científico de datos',
    en_US: 'Data scientist',
  },
  Admin: {
    es_AR: 'Administrador',
    es_UY: 'Administrador',
    en_US: 'Administrator',
  },
  Analyst: {
    es_AR: 'Analista',
    es_UY: 'Analista',
    en_US: 'Analyst',
  },
}

export const roleTraduction = role => {
  const lang = localStorage?.getItem('lang') || 'es_AR'
  return role ? roles[role]?.[lang] : 'Sin rol'
}
