import { useAuth0 } from '@auth0/auth0-react'
import { useEffect, useState } from 'react'
import { ruutsApi } from '../../services/ruutsApi'
import { useCustomSnackbarError } from '../../utils/Snackbar/useCustomSnackbarError'

export const useMetricEvents = ({ farmId, namespace, year, paddockIds }) => {
  const [metricEvents, setMetricEvents] = useState([])
  const { getAccessTokenSilently } = useAuth0()
  const [isLoading, setIsLoading] = useState(true)
  const { handleError } = useCustomSnackbarError()

  useEffect(() => {
    async function loadMetricEvents() {
      try {
        setIsLoading(true)
        const token = await getAccessTokenSilently()
        // TODO: Create Service and add it to the DB that returns also the paddocks associated
        const result = await ruutsApi.getMetricEvents({
          farm: { id: farmId },
          namespace,
          paddockIds,
          year,
          token,
        })
        setMetricEvents(result)
      } catch (error) {
        handleError(error)
      } finally {
        setIsLoading(false)
      }
    }

    loadMetricEvents()
  }, [farmId, getAccessTokenSilently, handleError, namespace, paddockIds, year])

  return { metricEvents, isLoading }
}
