import { dataCollectionStatementService } from '../farmMapping/datacollectionStatement.js'
import { documents } from '../farmMapping/documents.js'
import getFarms, { getFarm, getFarmsNameList } from '../farmMapping/getFarms.js'
import { samplingAreas } from '../farmMapping/samplingAreas/index.js'
import { paddocks } from '../farmMapping/savePaddocks.js'
import deleteApiData from './deleteApiData.js'
import deleteMetric from './deleteMetric.js'
import saveFinding from './findings/saveFinding.js'
import getDataCollectionFormDefinitions from './formDefinitions/getFormDefinitions.js'
import getApiData from './getApiData.js'
import getFormDefs from './getFormDefs.js'
import getMetricEvents from './getMetricEvents.js'
import getProgram from './getProgram.js'
import getProgramConfig from './getProgramConfig.js'
import getSoilsIntersections from './getSoils.js'
import postApiData, { postApiDataWithPayloadResponse } from './postApiData.js'
import saveMetrics, { saveMetricEventStatus, saveVerificationMetric } from './saveMetrics.js'
import updateApiData from './updateApiData.js'

export const ruutsApi = {
  getApiData,
  updateApiData,
  getFormDefs,
  postApiData,
  postApiDataWithPayloadResponse,
  getMetricEvents,
  getDataCollectionFormDefinitions,
  dataCollectionStatementService,
  deleteApiData,
  saveMetrics,
  saveVerificationMetric,
  saveMetricEventStatus,
  deleteMetric,
  getProgramConfig,
  getProgram,
  getSoilsIntersections,
  saveFinding,
  getFarms,
  getFarm,
  getFarmsNameList,
  paddocks,
  documents,
  samplingAreas,
}
