import { useAuth0 } from '@auth0/auth0-react'
import {
  Box,
  CircularProgress,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material'
import { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import AppContext from '../../../context/appContext'
import { useFormDefinitions } from '../../../hooks/data/useFormDefinitions'
import { generateReport } from '../../../services/reports/reportService'
import AttestationReportForm from '../Attestation/AttestationReportForm'

export const ReportsHome = () => {
  const navigate = useNavigate()
  const { setLoadingModalConfig, currentFarm } = useContext(AppContext)
  const [selectedReportType, setSelectedReportType] = useState('')
  const { formDefinitions, isLoading } = useFormDefinitions()
  const namespaces = formDefinitions?.map(({ label, namespace }) => ({
    label,
    namespace,
  }))
  const { getAccessTokenSilently } = useAuth0()

  useEffect(() => {
    setLoadingModalConfig({
      open: isLoading,
      dialogMessage: 'Cargando información...',
    })
  }, [isLoading, setLoadingModalConfig])

  const submitReport = async data => {
    const token = await getAccessTokenSilently()
    const generatedReport = await generateReport({
      reportData: {
        ...data,
        type: selectedReportType,
        farmId: currentFarm.id,
      },
      token,
    })
    navigate(`/reports/${generatedReport.type}/${generatedReport.id}`)
  }

  const REPORT_TYPES = {
    Attestation: <AttestationReportForm namespaces={namespaces} onSubmit={submitReport} />,
  }

  if (isLoading) return <CircularProgress />

  return (
    <Box
      sx={{
        display: 'flex',
        padding: '16px',
        justifyContent: 'center',
        flexDirection: 'column',
        gap: '8px',
      }}
    >
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">Tipo de reporte</InputLabel>
        <Select
          id="report-type-select"
          label="Tipo de reporte"
          labelId="report-type-select-label"
          value={selectedReportType}
          onChange={event => setSelectedReportType(event.target.value)}
        >
          {Object.keys(REPORT_TYPES).map(reportType => (
            <MenuItem key={reportType} value={reportType}>
              {reportType}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Divider />
      {REPORT_TYPES[selectedReportType]}
    </Box>
  )
}
