import Checkbox from '@mui/material/Checkbox'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import { FormControlLabel, FormGroup, Stack } from '@mui/material'

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
const checkedIcon = <CheckBoxIcon fontSize="small" />

const PaddocksList = ({
  paddocks,
  selectedPaddockIds,
  setSelectedPaddockIds,
  usedPaddockIds,
  formMode,
  allSelected,
  setAllSelected,
}) => {
  const handleListSelection = selection => {
    const newSelected = selection.map(s => s.id)
    setSelectedPaddockIds(newSelected)
  }

  const handleSelectAll = () => {
    if (!allSelected) {
      const newSelected = paddocks
        .filter(({ id }) => !usedPaddockIds.includes(id))
        .map(paddock => paddock.id)
      setAllSelected(true)
      setSelectedPaddockIds(newSelected)
    } else {
      setAllSelected(false)
      setSelectedPaddockIds([])
    }
  }

  return (
    paddocks &&
    usedPaddockIds &&
    selectedPaddockIds && (
      <Stack
        direction="row"
        display="flex"
        justifyContent="space-between"
        pb={1}
        pt={1}
        sx={{ width: '100%', alignItems: 'center', backgroundColor: '#eceff1' }}
      >
        <Autocomplete
          disableCloseOnSelect
          multiple
          defaultValue={paddocks.filter(p => selectedPaddockIds.includes(p.id))}
          disabled={formMode.readOnly}
          getOptionDisabled={option => usedPaddockIds.includes(option.id)}
          getOptionLabel={option => option.label}
          id="checkboxes-tags-demo"
          isOptionEqualToValue={(option, value) => {
            return option.id === value.id
          }}
          limitTags={2}
          options={paddocks}
          renderInput={params => <TextField {...params} label="Selecciona Lotes" placeholder="" />}
          renderOption={(props, option, { selected }) => (
            <li {...props}>
              <Checkbox
                checked={selected || selectedPaddockIds.includes(option.id)}
                checkedIcon={checkedIcon}
                icon={icon}
                size="small"
                style={{ marginRight: 8 }}
              />
              {option.label}
            </li>
          )}
          size="small"
          sx={{ width: '350px', paddingRight: 1, whiteSpace: 'nowrap' }}
          value={paddocks.filter(p => selectedPaddockIds.includes(p.id))}
          onChange={(event, value) => {
            handleListSelection(value)
          }}
        />
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={allSelected}
                disabled={formMode.readOnly}
                onChange={handleSelectAll}
              />
            }
            label="Seleccionar todos los lotes disponibles"
          />
        </FormGroup>
      </Stack>
    )
  )
}

export default PaddocksList
