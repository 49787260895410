import { Box, IconButton, Stack } from '@mui/material'
import Divider from '@mui/material/Divider'
import Typography from '@mui/material/Typography'
import { useContext } from 'react'
import { useParams } from 'react-router'
import AppContext from '../../context/appContext'
import { PROGRAMS } from '../../utils/constants'
import { roleTraduction } from '../../utils/roleTraduction'
import { pickChildrenByType } from '../../utils/childrenPickers'

const Title = ({ children }) => (
  <Box display="flex" flexGrow={1} justifyContent="center">
    {children}
  </Box>
)

const BackButton = ({ handleNavBack, children }) => (
  <IconButton onClick={() => handleNavBack()}>{children}</IconButton>
)

const FarmDetails = () => {
  const { currentFarm, userRoleSelected, programConfig } = useContext(AppContext)
  return (
    <Stack
      alignItems="right"
      direction="column"
      display="flex"
      justifyContent="center"
      spacing={0}
      textAlign="right"
    >
      <Typography sx={{ lineHeight: '0.9rem' }} variant="bodyBold">
        {currentFarm?.name.toUpperCase()}
      </Typography>
      <Typography sx={{ lineHeight: '0.8rem' }} variant="captionLight">
        {`${programConfig?.programId !== 99 ? 'Programa: ' : ''}${PROGRAMS[programConfig?.programId]}`}
      </Typography>
      <Typography sx={{ lineHeight: '0.8rem' }} variant="captionLight">
        {`Rol: ${roleTraduction(userRoleSelected)}`}
      </Typography>
    </Stack>
  )
}

export const NavHeader = ({ children }) => {
  const { year } = useParams()

  return (
    <>
      <Box
        alignItems="center"
        display="flex"
        flexGrow={0}
        justifyContent="space-around"
        m={0}
        position="sticky"
      >
        {pickChildrenByType(children, BackButton)}
        {pickChildrenByType(children, Title)}
        <Stack
          alignItems="center"
          direction="row"
          justifyContent="flex-end"
          padding={0.5}
          spacing={1}
        >
          {pickChildrenByType(children, FarmDetails)}
          <Typography
            fontSize="1.8rem"
            fontWeight="600"
            pr={1}
            sx={{ lineHeight: '1.8rem' }}
            variant="h6"
          >
            {year || ''}
          </Typography>
        </Stack>
      </Box>

      <Divider />
    </>
  )
}

NavHeader.Title = Title
NavHeader.BackButton = BackButton
NavHeader.FarmDetails = FarmDetails
