import { useAuth0 } from '@auth0/auth0-react'
import { useEffect, useState } from 'react'
import { ruutsApi } from '../../services/ruutsApi'
import { useCustomSnackbarError } from '../../utils/Snackbar/useCustomSnackbarError'

export const useSamplingAreas = ({ farmId }) => {
  const [samplingAreas, setSamplingAreas] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const { getAccessTokenSilently } = useAuth0()
  const { handleError } = useCustomSnackbarError()

  useEffect(() => {
    async function loadSamplingAreas() {
      try {
        setIsLoading(true)
        const token = await getAccessTokenSilently()
        const result = await ruutsApi.samplingAreas.getUncroppedByFarm({ farmId, token })
        if (result) {
          const apiSamplingAreas = result.map(samplingArea => samplingArea?.toGeoJSON)
          setSamplingAreas(apiSamplingAreas)
        }
      } catch (error) {
        handleError(error)
      } finally {
        setIsLoading(false)
      }
    }

    if (farmId) {
      loadSamplingAreas()
    }
  }, [farmId, getAccessTokenSilently, handleError])

  return { samplingAreas, isLoading }
}
