import { useAuth0 } from '@auth0/auth0-react'
import { useEffect, useState } from 'react'
import { getReport } from '../../services/reports/reportService'
import { useCustomSnackbarError } from '../../utils/Snackbar/useCustomSnackbarError'

export const useReport = ({ reportId }) => {
  const [report, setReport] = useState(null)
  const { handleError } = useCustomSnackbarError()
  const [isLoading, setIsLoading] = useState(true)
  const { getAccessTokenSilently } = useAuth0()

  useEffect(() => {
    const loadReport = async () => {
      try {
        setIsLoading(true)
        const token = await getAccessTokenSilently()
        const result = await getReport({ reportId, token })
        setReport(result)
      } catch (error) {
        handleError(error)
      } finally {
        setIsLoading(false)
      }
    }

    loadReport()
  }, [getAccessTokenSilently, reportId, handleError])

  return { report, isLoading }
}
