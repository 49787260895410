import { useAuth0 } from '@auth0/auth0-react'
import { useEffect, useState } from 'react'
import { useCustomSnackbarError } from '../../utils/Snackbar/useCustomSnackbarError'
import getApiData from '../../services/ruutsApi/getApiData'
import endpoints from '../../services/ruutsApi/endpoints'
import { ruutsApi } from '../../services/ruutsApi'

export const useFarmSubdivisionsList = (farmId, year) => {
  const [farmSubdivisions, setFarmSubdivisions] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const { getAccessTokenSilently } = useAuth0()
  const { handleError } = useCustomSnackbarError()

  useEffect(() => {
    const getFarmSubdivisions = async () => {
      try {
        setIsLoading(true)
        const token = await getAccessTokenSilently()
        const newFarmSubdivisions = await getApiData(
          endpoints.farmSubdivisions,
          { farmId, year },
          token,
        )
        const newPaddocks = await ruutsApi.paddocks.getPaddocks({
          farmId,
          token,
        })

        const result = newFarmSubdivisions
          .map(subdivision => {
            const paddocks = newPaddocks
              .filter(paddock => subdivision.paddockIds.includes(paddock.id))
              .map(paddock => paddock.toGeoJSON)
            return {
              name: `Loteo - ${subdivision.year}`,
              features: paddocks,
            }
          })
          .sort((a, b) => {
            const yearA = parseInt(a.name.split(' - ')[1], 10)
            const yearB = parseInt(b.name.split(' - ')[1], 10)

            return yearB - yearA
          })

        setFarmSubdivisions(result)
      } catch (error) {
        handleError(error)
        setFarmSubdivisions([])
      } finally {
        setIsLoading(false)
      }
    }
    getFarmSubdivisions()
  }, [farmId, year, getAccessTokenSilently, handleError])

  return { farmSubdivisions, isLoading }
}
